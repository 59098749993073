import React, { useEffect, useContext } from "react";
import M from "materialize-css";
import P2cContext from '../../context/P2c/p2cContext';
import ClientContext from '../../context/Client/clientContext';
import { useForm } from "react-hook-form";
import { removeNonDigits, dateP2c } from '../../helpers/str';

const P2c0UP0 = () => {
  // ...existing code...

  const finalDate = dateP2c(new Date(Date.now()));
  const p2cContext = useContext(P2cContext);
  const { sendP2c0UP0 } = p2cContext;
  const clientContext = useContext(ClientContext);
  const { setLoading, info } = clientContext;

  const { handleSubmit } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  });


  useEffect(() => {
    M.AutoInit();
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = interdata => {
    setLoading(true)
    const message = {
      date: finalDate,
      bank: interdata.bank,
      m: info[0].amount_bs,
      phoneP: `00${removeNonDigits(interdata.phoneP)}`,
      order: info[0].orderId,
    }
    sendP2c0UP0(message)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* ...existing code... */}
    </form>
  );
};

export default P2c0UP0;
