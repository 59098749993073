import React, { useContext, useEffect } from 'react';
import './styles/form.css';
import './styles/transactionResult.css';
import PayContext from '../context/Pay/payContext';
import ClientContext from '../context/Client/clientContext';
import InternacionalContext from '../context/Internacional/internacionalContext';
import debitContext from '../context/payDebit/debitContext';
import P2cContext from '../context/P2c/p2cContext';
import C2pContext from '../context/C2p/c2pContext';
import ubiiAppContext from '../context/ubiiApp/ubiiAppContext';
import ZelleContext from '../context/zelle/zelleContext';
import cashContext from '../context/Cash/cashContext';
import errorContext from '../context/Error/errorContext';
import TddSelection from './debit/TddSelection';
import TddAuth from './debit/TddAuth';
import Tdd from './debit/Tdd';
import P2c from './p2c/P2c';
import Tdc from './tdc/Tdc';
import TdcInternacional from './internacional/TdcInternacional';
import UbiiApp from './ubiiapp/UbiiApp';
import Zelle from './zelle/Zelle';
import CashPay from './cash/CashPay';
import C2p from './c2p/C2P';
import Recibo from './Recibo';
import NotFound from './NotFound';
import Spinner from './spinner/Spinner';
import BackButton from './BackButton';
import Method from './Method';
import { timer, timer2 } from '../helpers/timeLimit';
import MicroCobro from './tdc/MicroCobro';
import { isInstagram } from '../helpers/browser-check';
import P2c0104 from './p2c/P2c0104';
import P2c0UP0 from './p2c/P2c0UP0';

const Form = () => {
    
    //Extraigo todos los datos necesarios de todos los context
    const {info, type_app, loading, payment, method, selectedCoin, error_data, handleError} = useContext(ClientContext);
    const {processPayment, pagoData, processChargeMicroCTDC} = useContext(PayContext);
    const {pagoInternacional, processPaymentInter,processChargeClient} = useContext(InternacionalContext);
    const {processPaymentDebit, processAuth, dataDebit, response, options} = useContext(debitContext);
    const {dataP2c, processP2c,dataP2c0104,processP2c0104} = useContext(P2cContext);
    const {dataUbiiApp, processUbiiApp} = useContext(ubiiAppContext);
    const {dataZelle, processZelle} = useContext(ZelleContext);
    const {dataC2p, processC2p} = useContext(C2pContext);
    const {dataCash, processCash} = useContext(cashContext);
    const { alert, showError } = useContext(errorContext);
    const [msg, img] = alert;

    /**
     * Renderizado de la página dependiendo del método y moneda seleccionado por el usuario
     * @function FormMethod
     * @returns {HTMLElement}
     */
    const FormMethod=()=>{
        console.log("method",method);
        console.log("selectedCoin",selectedCoin);   
        console.log("payment",payment);   
        if(payment===1){
            return type_app===2 ? <Method addAmount={true}/> : <Method addAmount={false}/>;
        }
        
        if(method.btn_alias === 'TDC' && selectedCoin === 'bs'){
            switch(payment){
                case 2:
                    return <Tdc/>;
                case 3:
                    return processChargeMicroCTDC===true ? <MicroCobro pay_method="tdc_bvc"/> : <Spinner/>;
                default:
                    return <NotFound message="Ocurrió un problema, por favor, vuelva a intentar" image='/images/error_broken.svg'/>;       
            }
        }else if(method.btn_alias === 'TDD' &&  selectedCoin === 'bs'){
            switch(payment){
                case 2:
                    return <TddSelection/>;
                case 3:
                    return <TddAuth options={options}/>;
                case 4:
                    return processAuth===true ? <Tdd bank={method.btn_short}/> : <Spinner/>;
                default:
                    return <NotFound message="Ocurrió un problema, por favor, vuelva a intentar" image='/images/error_broken.svg'/>;       
            }
        }else if(method.btn_alias === 'TDCI' && selectedCoin === 'ds'){
            switch(payment){
                case 2:
                    return <TdcInternacional/>;
                case 3:
                    return processChargeClient===true ? <MicroCobro pay_method="tdci"/> : <Spinner/>;
                default:
                    return <NotFound message="Ocurrió un problema, por favor, vuelva a intentar" image='/images/error_broken.svg'/>;       
            }
            
        }else if(method.btn_alias === 'ZELLE' && selectedCoin === 'ds'){
            switch(payment){
                case 2:
                    return <Zelle/>;
                default:
                    return <NotFound message="Ocurrió un problema, por favor, vuelva a intentar" image='/images/error_broken.svg'/>;    
            }
        }else if(method.btn_alias === 'C2P' && selectedCoin === 'bs'){
            return <C2p/>;
        }else if(method.btn_alias ==='UBIIAPP'){
            return <UbiiApp/>;
        }else if(method.btn_alias ==='CASH'){
            return <CashPay/>;
        }else if (method.btn_alias === 'P2CR' && selectedCoin === 'bs') {
            if (method.btn_bank2 === '0104') {
                
                return <P2c0104 />;
            } else if (method.btn_bank2 === '0UP0') {
                return <P2c0UP0 />;
            }
        }else{
            switch(payment){
                case 2:
                    return <P2c/>;
                default:
                    return <NotFound message="Ocurrió un problema, por favor, vuelva a intentar" image='/images/error_broken.svg'/>;    
            } 
        }
    }

    useEffect(() => {
        let time;    
        // se inician los 10 min (600000)
        if(type_app && !error_data){
            time = (type_app!==1) ? timer2(600000, ()=>{
                showError('8');
                handleError('8');
            }) : timer(600000,"App");
        };
    
        return function cleanup () {
            clearTimeout(time)
        };
    }, [type_app]);

    return (
        <>
        {(loading || payment===0) ? <Spinner/> :

            error_data ? (<NotFound message={msg} image={img}/>)  :

                (
                    <div className="row valign-wrapper" style={{marginBottom:"0px"}}>
                        {
                            (processPaymentInter === true || processPayment===true || processPaymentDebit===true || processP2c===true || processP2c0104===true  || processUbiiApp===true || processZelle===true || processCash===true || processC2p===true || processAuth===false) ?                   
                                <Recibo
                                    viewData={pagoData || pagoInternacional || dataDebit || dataP2c || dataP2c0104 || dataUbiiApp || dataZelle || dataCash || dataC2p || response}
                                    authFailed={processAuth===false? true : null}
                                />
                            :
                            <div className={`${isInstagram() ? 'front-card-instagram ' : ''}col s12 m9 shadow frontCard`}>
                                <div className="payment">
                                    <BackButton/>
                                    <div className={`col s12 center-align`} style={{marginTop: payment>1 && "1.9rem"}} id="logo">
                                        <h4 className="title-commerce">{info[2].name_comercio}</h4>
                                    </div>
                                    <FormMethod/>

                                    <div className={`col s12 center-align`} style={{height:"1.6rem", marginBottom: "2rem"}} id="logo">
                                        <img style={{height:"inherit"}} src="https://www.ubiipagos.com/img/logo.png" alt="logo"/>
                                    </div>
                                </div>
                            </div>
                        }      
                    </div>
                )
        }
        </>
     );
}

export default Form;
