import {PAGO_P2C, PAGO_P2C_0104, PAGO_P2C_0UP0} from '../../types';

export default (state,action) =>{

    const {type, payload} = action;

 switch (type) {
     case PAGO_P2C:
        return{
            ...state,
            dataP2c:payload,
            processP2c:true,
            loading:false
        }
     case PAGO_P2C_0104:
        return{
            ...state,
            dataP2c0104:payload,
            processP2c0104:true,
            loading:false
        }
     case PAGO_P2C_0UP0:
        return{
            ...state,
            dataP2c0UP0:payload,
            processP2c0UP0:true,
            loading:false
        }
     default:
        return state;
 }
}