import React , {useReducer, useContext} from 'react';
import P2cContext from '../P2c/p2cContext';
import p2cReducer from '../P2c/p2cReducer';
import { PAGO_P2C, PAGO_P2C_0104, PAGO_P2C_0UP0 } from '../../types';
import ClientContext from '../Client/clientContext';
import errorContext from '../Error/errorContext';

const P2cState = (props) => {

    //State
    const initialState ={
        dataP2c:null,
        processP2c:false,
        dataP2c0104: null,
        processP2c0104: false,
        dataP2c0UP0: null,
        processP2c0UP0: false
    }

    const clientContext=useContext(ClientContext);
    const {setLoading,method,handleError,pay}=clientContext; 
    const { showError } = useContext(errorContext);

    //dispacth para ejecutar las acciones
    const [state, dispatch] = useReducer(p2cReducer,initialState);

    /**
     * Envía datos al endpoint final de p2c. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendP2c
     * @param {object} datos información enviada desde el formulario de p2c
     * @returns {void}
     */
    const sendP2c = async datos =>{
        try {
            const response = await pay('payment_pago_movil', datos, method.btn_key);
            dispatch({
                type:PAGO_P2C,
                payload:response
            })
            setLoading(false)

        } catch (error) {
            console.log(error);
            showError('1');
            handleError(error);
            setLoading(false)
        }
    }

    /**
     * Envía datos al endpoint final de p2c para 0104. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendP2c0104
     * @param {object} datos información enviada desde el formulario de p2c0104
     * @returns {void}
     */
    const sendP2c0104 = async datos =>{
        try {
            console.log(datos,method.btn_key);
            const response = await pay('payment_pago_movil_bvc', datos, method.btn_key);
            dispatch({
                type:PAGO_P2C_0104,
                payload:response
            })
            setLoading(false)

        } catch (error) {
            showError('1');
            handleError(error);
            setLoading(false)
        }
    }

    /**
     * Envía datos al endpoint final de p2c para 0UP0. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendP2c0UP0
     * @param {object} datos información enviada desde el formulario de p2c0UP0
     * @returns {void}
     */
    const sendP2c0UP0 = async datos =>{
        try {
            const response = await pay('payment_pago_movil_0UP0', datos, method.btn_key);
            dispatch({
                type:PAGO_P2C_0UP0,
                payload:response
            })
            setLoading(false)

        } catch (error) {
            console.log(error);
            showError('1');
            handleError(error);
            setLoading(false)
        }
    }

    return ( 
        <P2cContext.Provider
            value={{
                dataP2c: state.dataP2c,
                processP2c:state.processP2c,
                sendP2c,
                dataP2c0104: state.dataP2c0104,
                processP2c0104: state.processP2c0104,
                sendP2c0104,
                dataP2c0UP0: state.dataP2c0UP0,
                processP2c0UP0: state.processP2c0UP0,
                sendP2c0UP0
            }}
        >
            {props.children}
        </P2cContext.Provider>
     );
}
 
export default P2cState;