import {SET_LOADING,CHANGE_COIN,CHANGE_METHOD,FORM_PAYMENT,SET_METHODS,ERROR_DATA,CHANGE_METHOD_DEBIT, SET_APP,SET_TOTAL, SET_COUNTRIES} from '../../types';

export default (state,action) =>{

    const {type, payload} = action;
    switch (type) {
        case SET_LOADING:
            return{
                ...state,
                loading:payload,
            }
        case CHANGE_COIN:
            return{
                ...state,
                selectedCoin:payload,
                selectedMethods:state.methods.filter(method=>method.coin === payload),
                method:''
            }
        case CHANGE_METHOD:
            return{
                ...state,
                method: state.methods.filter(m => !m.btn_bank).find(method => method.btn_key === payload) || ""
            }
        //CASO ESPECIAL QUE CONTEMPLA LOS DISTINTOS BANCOS DENTRO DEL METODO DEBIT
        case CHANGE_METHOD_DEBIT:
            return {
                ...state,
                method: state.methods.find(method => method.btn_bank === payload)
            }
        case FORM_PAYMENT:
            return{
                ...state,
                payment:payload
            }
        case SET_METHODS:
            const clientP2c = payload.find(m => m.btn_alias==="P2C" ||  m.btn_alias==="P2CR");
            const methodsUs = payload.filter(m => m.coin === "ds");
            const methodsVes = payload.filter(m => m.coin === "bs" && !m.btn_bank);
            return {
                ...state,
                methods: payload,
                usMethods: methodsUs,
                vesMethods: methodsVes,
                usCoinActive: !!methodsUs.length,
                vesCoinActive: !!methodsVes.length,
                clientP2c: clientP2c && clientP2c.p2c_info
            }
        case ERROR_DATA:
            return{
                ...state,
                error_data:payload
            }
        case SET_APP:
            return {
                ...state,
                type_app: payload
            }
        case SET_TOTAL:
            return {
                ...state,
                total:payload
            }
        case SET_COUNTRIES:
            return {
                ...state,
                countries: payload
            }
        default:
            return state;
    }
}