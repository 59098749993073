import React, { useState, useEffect, useContext } from "react";
import M from "materialize-css";
import P2cContext from '../../context/P2c/p2cContext';
import ClientContext from '../../context/Client/clientContext';
import { useForm, Controller } from "react-hook-form";
import Spinner from "../spinner/Spinner";
import Input from '../partials/Input';
import { removeNonDigits, dateP2c, validateRif } from '../../helpers/str';
import { InputDisplayForCurrency } from "../partials/CurrencyMask";
import Select from "react-select";
import { customStyles } from "../internacional/TdcInternacional";

const P2c0104 = () => {

    //States
    const [pmovil, setPmovil] = useState({
        bank: '',
        phoneP: '',
        ref: '',
        letter: 'V',
        ci: '',
        date: new Date().toISOString().split('T')[0] // Set default date to today
    });

    const { bank, phoneP, ref, letter, ci, date } = pmovil;
    const finalDate = dateP2c(new Date(Date.now()));
    const p2cContext = useContext(P2cContext);
    const { sendP2c0104 } = p2cContext;
    const clientContext = useContext(ClientContext);
    
    const { loading, setLoading, method, info ,clientP2c} = clientContext;

    const { register, handleSubmit, trigger, control, formState: { errors } } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    });

    const handleChange = (e, errors) => {
        if (errors) {
            trigger(e.target.name);
        }
        if (e.bank_code) {
            setPmovil({
                ...pmovil,
                bank: e.bank_code
            });
        } else {
            if (e.target.name === "phoneP" && e.target.value === "+58 (") {
                e.target.value = ""
            }
            setPmovil({
                ...pmovil,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        M.AutoInit();
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = interdata => {
        setLoading(true)
        const formattedDate = interdata.date.replace(/-/g, '');
        const message = {
            date: finalDate,
            bank: interdata.bank,
            m: info[0].amount_bs,
            phoneP: `00${removeNonDigits(interdata.phoneP)}`,
            order: info[0].orderId,
            ref: interdata.ref,
            ci: `${interdata.letter}-${interdata.ci}`,
            date: formattedDate
        }
        sendP2c0104(message)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="margin-top-08 col s12 l12 titles-2 center-align">
                <h5>Información del pago</h5>
            </div>
            {loading && <Spinner />}
            <div className="row">

                <div className="input-field col s12 center-align">
                    <div className="center-align">
                        <img src="/images/icons/checker.svg" alt="chequera" />
                    </div>
                    <label className='active label-payments' htmlFor="totalAmount">Verifica y toma nota del monto a pagar en bolívares.</label>
                    <InputDisplayForCurrency
                        amount={info[0].amount_bs}
                        insideForm
                    />
                </div>
                <div className="input-field col s12 center-align">
                    <div className="center-align">
                        <img src="/images/icons/phone-pay.svg" alt="correo" />
                    </div>
                    <label className='active label-payments' htmlFor="email">Realiza el pago móvil con los datos presentados a continuación.</label>
                    <div className="ubiiCard">
                        <p className="b">Nombre</p>
                        <p className="mb">{clientP2c.nombre}</p>
                        <p className="b">RIF</p>
                        <p className="mb">{clientP2c.rif}</p>
                        <p className="b">Banco</p>
                        <p className="mb">{clientP2c.banco}</p>
                        <p className="b">Número Telefónico</p>
                        <p className="mb">{clientP2c.telf}</p>
                    </div>
                </div>
                <div className={`input-field col s12 center-align ${errors.bank ? "border-input-dropdown-error" : ""}`}>
                    <div className="center-align">
                        <img src="/images/icons/bank.svg" alt="banco" />
                    </div>
                    <label className='active label-payments' htmlFor="bank">Selecciona el banco que usaste para el pago</label>
                    <Controller
                        id="bank-drop"
                        control={control}
                        name="bank"
                        rules={{ required: { value: true, message: 'El banco es requerido' } }}
                        render={({ field: { onChange } }) => (
                            <Select
                                options={method.banks_p2c}
                                getOptionLabel={(option) => option.bank_name}
                                getOptionValue={(option) => option.bank_code}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={customStyles}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => 'Banco no encontrado'}
                                placeholder="Selecciona el banco"
                                onChange={(e) => {
                                    onChange(e.bank_code);
                                    e.target = { name: "bank" };
                                    handleChange(e, errors.bank);
                                }}
                                autoComplete="off"
                            />
                        )}
                    />
                    <span className="helper-text invalid" data-error="wrong">
                        {errors.bank && errors.bank.message}
                    </span>
                </div>

                <div className="col s12 label-dni">
                    <label className='active label-payments' htmlFor="letter">Datos de tu documento de identidad</label>
                </div>
                <div className={`input-field col s8 offset-s2 m3 offset-m0 center-align no-margin-bottom`}>
                    <select
                        name="letter"
                        id="letter"
                        {...register("letter", {
                            required: { value: true, message: 'La nacionalidad es obligatoria' },
                            onChange: (e) => handleChange(e, errors.letter)
                        })}
                        className="input-form"
                        autoComplete="off"
                    >
                        <option value="V">V</option>
                        <option value="E">E</option>
                        <option value="J">J</option>
                        <option value="G">G</option>
                        <option value="C">C</option>
                    </select>
                </div>

                <div className="input-field col s12 m9">
                    <Input
                        name="ci"
                        id="ci"
                        mask="999999999"
                        register={register("ci", {
                            required: { value: true, message: 'Este campo es obligatorio' },
                            validate: value => validateRif(value, letter) || "El RIF no es válido",
                            ...(letter === "J" ?
                                { minLength: { value: 9, message: "Este campo debe tener 9 caracteres" } } :
                                { minLength: { value: 6, message: "Este campo debe tener al menos 6 caracteres" } }
                            ),
                            onChange: (e) => handleChange(e, errors.ci)
                        })}
                        placeholder="Cédula/RIF"
                        className={errors.ci ? "red-border" : "input-form"}
                    />
                    <span className="helper-text invalid" data-error="wrong">
                        {errors.ci && errors.ci.message}
                    </span>
                </div>

                <div className="input-field col s12 center-align">
                    <label className='active label-payments' htmlFor="phoneP">Ingresa tu número de teléfono</label>
                    <Input
                        name="phoneP"
                        id="phoneP"
                        mask={phoneP !== "" ? "+58 (999) 999 99 99" : ""}
                        register={register("phoneP", {
                            required: { value: true, message: 'El número telefónico del cliente es obligatorio' },
                            minLength: { value: 19, message: "Coloque un número telefónico válido" },
                            onChange: (e) => handleChange(e, errors.phoneP)
                        })}
                        placeholder="+58 ("
                        className={errors.phoneP ? "red-border" : "input-form"}
                        inputMode="numeric"
                    />
                    <span className="helper-text invalid" data-error="wrong">
                        {errors.phoneP && errors.phoneP.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="phoneP">Selecciona la fecha del pago</label>
                    <Input
                        name="date"
                        id="date"
                        type={"date"}
                        defaultValue={date} // Set default value to today
                        register={register("date", {
                            required: { value: true, message: 'Este campo es obligatorio' },
                            validate: value => {
                                const selectedDate = new Date(value);
                                const today = new Date();
                                return selectedDate <= today || "La fecha no puede ser futura";
                            },
                            onChange: (e) => handleChange(e, errors.date)
                        })}
                        placeholder="Fecha de pago"
                        className={errors.date ? "red-border" : "input-form"}
                    />
                    <span className="helper-text invalid" data-error="wrong">
                        {errors.date && errors.date.message}
                    </span>
                </div>

                <div className="input-field col s12 center-align">
                    <label className='active label-payments' htmlFor="ref">Ingresa la referencia del pago</label>
                    <Input
                        name="ref"
                        id="ref"
                        mask="99999999"
                        register={register("ref", {
                            required: { value: true, message: 'La referencia es obligatoria' },
                            minLength: { value: 6, message: "La referencia debe tener al menos 6 caracteres" },
                            maxLength: { value: 8, message: "La referencia debe tener máximo 8 caracteres" },
                            onChange: (e) => handleChange(e, errors.ref)
                        })}
                        placeholder="Referencia"
                        className={errors.ref ? "red-border" : "input-form"}
                    />
                    <span className="helper-text invalid" data-error="wrong">
                        {errors.ref && errors.ref.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <button
                        className="buttonPay"
                        disabled={phoneP === "" || bank === "" || ci === "" || ref === ""}
                    >
                        ¡Listo!
                    </button>
                </div>
            </div >
        </form >
    );
};

export default P2c0104;
