import React,{useReducer, useState, useEffect, useContext} from 'react';
import {SET_LOADING,CHANGE_COIN,CHANGE_METHOD,FORM_PAYMENT,SET_METHODS,ERROR_DATA,CHANGE_METHOD_DEBIT, SET_APP,SET_TOTAL, SET_COUNTRIES} from '../../types';
import clientAxios from '../../config/axios';
import jwt_decode from "jwt-decode";
import clientContext from './clientContext';
import clientReducer from './clientReducer';
import { emailValidator } from '../../helpers/str';
import { timer, stopTimer, stopTimer2 } from '../../helpers/timeLimit';
import CryptoJS from 'crypto-js';
import errorContext from '../Error/errorContext';
import { useLocation } from 'react-router-dom';
import { isIframe, isInstagram, isIos } from '../../helpers/browser-check';

const ClientState= props=>{

    const { showError } = useContext(errorContext);
    const years = [];
    const currentYear=parseInt(new Date().getFullYear());
    for (let i = (currentYear-10); i < currentYear+11; i++) {
        years.push(<option value={i} key={i}>{i}</option>)   
    };
    //Variable para el tiempo de espera antes de mostrar error
    let load;

    //states de info del otro site
    const [info, setInfo] = useState([{}]);

    //Para catchear las rutas
    const location = useLocation();

    //para los métodos que requieren lat y long
    const [direction, setDirection] = useState({
        latitude: "",
        longitude:""
    })

    // Será true cuando se esté entrando por la librería en instagram, la página esté en un iframe, y sea abierta en Iphone
    // Esta combinación específica indica que se deben cambiar algunos estilos de materialize por el browser default
    const styleBrowserDefault = (isIframe() && isInstagram() && isIos());

    /**
     * Escucha los mensajes que recibe la página, los filtra y valida. Coloca en el contexto toda la información, 
     * o presenta el error si existe
     * @async
     * @function listen
     * @param {Event} e el mensaje recibido
     */
    const listen = async event =>{
        try{
            if(event.data.clientId){
                window.removeEventListener('message', listen, true)
                setLoading(true)
                clearTimeout(load)
                clientAxios.defaults.headers.common['X-CLIENT-ID']= event.data.clientId;
                clientAxios.defaults.headers.common['X-CLIENT-CHANNEL'] = 'BTN-WEB';
                const valid= await clientAxios.get('/check_client_id',{
                    params: {
                        order: event.data.orderId
                    },
                    headers: {
                        'X-CLIENT-DOMAIN': event.origin
                    }
                });

                if(valid.data.MS === 'Order already process'){
                    throw new Error('3')

                }else if(valid.data.R!=='0'){
                    throw new Error('2')
                }else{
                    if(!event.data.concept || !event.data.amount_ds || !event.data.amount_bs || !event.data.clientId || !event.data.orderId){
                        throw new Error('1')
                    }
                    if(event.data.concept === '' || event.data.principal === '' || event.data.orderId === ''){
                        throw new Error('1')
                    }
                    if(event.data.principal && event.data.principal !== 'bs' && event.data.principal !== 'ds'){
                        throw new Error('1')
                    }
                    if(!/^\d+(?:\.\d{2})$/.test(event.data.amount_ds) || !/^\d+(?:\.\d{2})$/.test(event.data.amount_bs)){
                        throw new Error('1')
                    }
                    if("custom_field" in event.data && typeof event.data.custom_field !== "object"){
                        throw new Error('1')
                    }
                    if("client" in event.data){
                        if (typeof event.data.client !== "object"){
                            throw new Error('1')
                        }else{
                            if (
                                event.data.client &&
                                "client_email" in event.data.client && (
                                    typeof event.data.client.client_email !== "string" ||
                                    !emailValidator.test(event.data.client.client_email)
                                )
                            ) {
                                throw new Error('1')
                            }
                        }
                    }
                    const akeys = await clientAxios.get('/get_keys',{
                        headers: {
                            authorization: valid.data.token
                        }
                    })
                    if(akeys.data.R==='0'){
                        const decoded = jwt_decode(valid.data.token);

                        clientMethod(akeys.data.Keys);

                        const countries = await clientAxios.get('/get_countrys');
                        setCountries(countries.data.Countrys);
                        setInfo([event.data,event.origin,valid.data,decoded]);

                        nextStep(1);
                    }else{
                        throw new Error('2')
                    }
                    stopTimer()
                    setLoading(false)    
                }
            }
        }catch(e){
            window.removeEventListener('message', listen, true);
            if(window.opener && e.message !== '2'){
                window.opener.postMessage({error_data: e.message}, event.origin)
                console.log(e)
            };
            showError(e.message);
            handleError(e);
            setLoading(false);
            timer(7000,"Recibo");
            nextStep(1);
        }
    }

    /**
     * Gestiona las transacciones que vienen por correo. Coloca en el contexto toda la información, o presenta el error si existe
     * @async
     * @function link
     * @param {string} url el url del botón
     */
    const link = async(url) =>{
        try{
            setLoading(true)
            clientAxios.defaults.headers.common['X-CLIENT-CHANNEL'] = 'BTN-LINK';
            const info = await clientAxios.get(`/get_link?info=${url[2]}`)
            
            //SOPORTAR ORDEN YA PROCESADA Y BOTON VENCIDO
            if(info.data.MS==="URL vencida"){
                throw new Error('4');
            }else if(info.data.MS==="Token usado"){
                throw new Error('3');
            }else if(info.data.MS==="No API Keys Found"){
                throw new Error('2');
            }
            
            if(info.data.R==="0"){
                clientAxios.defaults.headers.common['X-CLIENT-ID']= info.data.clientId

                const decoded = jwt_decode(info.data.token);

                clientMethod(info.data.keys);
                
                const countries = await clientAxios.get('/get_countrys');
                setCountries(countries.data.Countrys);
                
                setInfo([info.data,false,{
                    rif_comercio: info.data.rif_comercio,
                    token: info.data.token,
                    name_comercio: info.data.name_comercio
                },decoded]);
                
                nextStep(1);
            }else{
                throw new Error('5')
            }
            
            setLoading(false)
        }catch(e){
            console.log(e);
            showError(e.message);
            handleError(e);
            setLoading(false);
            stopTimer2();
            nextStep(1);
        }   
    };

    /**
     * Gestiona las transacciones que vienen por el link general. Coloca en el contexto toda la información, o presenta el error si existe
     * @async
     * @function generalLink
     * @param {string} url array con los elementos que conforman la ruta
     */
    const generalLink = async(url) => {
        try{
            clientAxios.defaults.headers.common['X-CLIENT-CHANNEL'] = 'BTN-LINK';
            const info = await clientAxios.get(`/ubiicommerce/${url[1]}`);
            if(info.data.R==="0"){
                clientAxios.defaults.headers.common['X-CLIENT-ID']= url[1]

                //En este estilo, en donde aparece el concepto debería aparecer el nombre del comercio
                info.data['concept'] = url[0];
                info.data['orderId']=info.data.order;

                const decoded = jwt_decode(info.data.token);
                
                clientMethod(info.data.keys);
                
                const countries = await clientAxios.get('/get_countrys');
                setCountries(countries.data.Countrys);

                setInfo([info.data,false,{
                    rif_comercio: info.data.rif_comercio,
                    token: info.data.token,
                    name_comercio: info.data.name_comercio
                },decoded]);

                nextStep(1);
            }else{
                throw new Error('2')
            }
            
            setLoading(false)
        }catch(e){
            console.log(e);
            showError(e.message);
            handleError(e);
            setLoading(false);
            stopTimer2();
            nextStep(1);
        }
    };

    /**
     * Función que se invoca si la url viene con parámetros, y define su validez y el tipo de link de pago
     * @function setTypeLink
     * @param {string} url array con los elementos que conforman la ruta
     */
    const setTypeLink = (url) => {
        try{
            if(url.length===2){
                setApp(2);
                generalLink(url);
            }else if(url.length===3) {
                setApp(3);
                link(url);
            }else{
                throw new Error('5');
            }
        }catch(e){
            console.log(e);
            showError(e.message);
            handleError(e);
            setLoading(false);
            timer(7000,"Recibo");
            nextStep(1);
        }
    };
    
    //Cuando el componente se monte (esté completamente renderizado), ahí devuelve una respuesta 
    //al botón para que el botón mande la orden (si sólo se manda el mensaje cuando se abre la ventana,
    //se pierde y hay que presionar otro botón para que llegue el mensaje)
    useEffect(() => {
        /**
         * Envía la señal sólo si la pasarela ha sido abierta a través de otra página
         * @function sendResponse
         */
        const sendResponse = () => {
            if(window.opener){
                load = setTimeout(() => {
                    showError('1')
                    handleError(new Error('1'))
                    setLoading(false)
                    nextStep(1)
                    timer(7000,"Recibo")
                }, 5000)
                window.addEventListener('message', listen, true)
                window.opener.postMessage('awake', '*')
            }else{
                showError('1')
                handleError(new Error('1'))
                window.removeEventListener('message', listen, true)
                setLoading(false)
                nextStep(1);
            }
        }

        //SI TIENE RUTAS
        if(location.pathname!=="/" && location.pathname!==""){
            setTypeLink(location.pathname.split('/').filter(Boolean));
        //SI NO TIENE NADA
        }else{
            setApp(1);
            sendResponse();
        }    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    
      const initialState={
        styleBrowserDefault:styleBrowserDefault,
        years:years,
        loading:true,
        months:[
            {'value':'01','description':'Enero'},
            {'value':'02','description':'Febrero'},
            {'value':'03','description':'Marzo'},
            {'value':'04','description':'Abril'},
            {'value':'05','description':'Mayo'},
            {'value':'06','description':'Junio'},
            {'value':'07','description':'Julio'},
            {'value':'08','description':'Agosto'},
            {'value':'09','description':'Septiembre'},
            {'value':'10','description':'Octubre'},
            {'value':'11','description':'Noviembre'},
            {'value':'12','description':'Diciembre'}
        ],
        coins:[
            {'value':'ds','description':'Dólares'},
            {'value':'bs','description':'Bolívares'},
        ],
        selectedCoin:'', //La moneda que selecciona el usuario
        methods:null, //Todos los métodos
        selectedMethods:null, //Los métodos filtrados por su moneda
        method:'', // El método que selecciona el usuario
        payment:0,
        type_app: undefined,
        total:0,
        error_data:undefined,
        clientP2c:undefined,
        usMethods:undefined,
        vesMethods:undefined,
        usCoinActive: false,
        vesCoinActive: false,
        countries: []
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(clientReducer, initialState)

    /**
     * Inicia o quita el loader de pantalla completa
     * @function setLoading
     * @param {boolean} condition true para iniciar el loader y false para terminarlo
     * @returns {void}
     */
    const setLoading= condition=>{
        dispatch({
            type:SET_LOADING,
            payload:condition
        })
    }

    /**
     * Setea el monto cuando está en el modo del link general
     * @function setTotal
     * @param {string} total el monto en formato 0.00
     * @returns {void}
     */
    const setTotal= total=>{
        dispatch({
            type:SET_TOTAL,
            payload:total
        })
    }

    /**
     * Define si la aplicación es con CDN o si emplea el link
     * @function setLoading
     * @param {boolean} type 1 para CDN, 2 para link general y 3 para link correo
     * @returns {void}
     */
    const setApp= type=>{
        dispatch({
            type:SET_APP,
            payload:type
        })
    }
    
    /**
     * Cambia los países disponibles en TDCI
     * @function setLoading
     * @param {object} countries objeto con los países
     * @returns {void}
     */
         const setCountries = countries=>{
            dispatch({
                type:SET_COUNTRIES,
                payload:countries
            })
        }

    /**
     * Cambia la moneda seleccionada por el usuario en el contexto
     * @function changeCoin
     * @param {string} coin la denominación de la moneda
     * @returns {void}
     */
    const changeCoin= coin=>{
        dispatch({
            type:CHANGE_COIN,
            payload:coin
        })
    }

    /**
     * Cambia el método de pago seleccionada por el usuario en el contexto
     * @function changeMethod
     * @param {string} method un método de pago
     * @returns {void}
     */
    const changeMethod= method=>{
        dispatch({
            type:CHANGE_METHOD,
            payload:method
        })
    }

    const changeMethodDebit = bank => {
        dispatch({
            type: CHANGE_METHOD_DEBIT,
            payload: bank
        })
    }

    /**
     * Cambia la variable del contexto para avanzar o retroceder en la pasarela
     * @function nextStep
     * @param {boolean} condition true para avanzar
     * @returns {void}
     */
    const nextStep= condition=>{
        dispatch({
            type:FORM_PAYMENT,
            payload:condition
        })
    }

    /**
     * Cambia todos los métodos de pago disponibles para el usuario
     * @function updateMethods
     * @param {Array<object>} cMethods un array con objetos método de pago
     * @returns {void}
     */
    const updateMethods = cMethods =>{
        dispatch({
            type: SET_METHODS,
            payload: cMethods
        })
    }

    /**
     * Setea la variable error del contexto
     * @function handleError
     * @param {Error} error un error que describa el fallo por el cual se invocó
     * @returns {void}
     */
    const handleError = error =>{
        dispatch({
            type: ERROR_DATA,
            payload: error
        })
    }

    /**
     * Crea los objetos método de pago según los tenga habilitados el cliente
     * @function clientMethod
     * @param {Array<object>} cMethods métodos habilitados por el cliente
     * @returns {void}
     */
    const clientMethod = (cMethods) =>{

        let auxMethods=[];
        let hasTdd=false;

        //Busca los métodos de pago por débito y le agrega unos atributos necesarios
        cMethods.filter(m=> m.btn_alias==="TDD").forEach(m=>{
            hasTdd=true;
            switch(m.btn_canal){
                case "0102":
                    m.btn_short='venezuela'
                    break;
                case "0105":
                    m.btn_short='mercantil'
                    break;
                case "0CC0":    
                    m.btn_short = 'credicard'
                    break;
                case "0104":
                    m.btn_short = 'bvc'
                    break;
                default:
                    break;
            }
        })

        //le agrega la moneda a todos los métodos acorde
        cMethods.forEach(m => {
            if(m.btn_alias==='TDCI' || m.btn_alias==='ZELLE' || m.btn_alias==='CASH'){
                m.coin = 'ds';
                if (m.btn_alias==='CASH' && !("cash_info" in m)){
                    return
                }
            }else{
                m.coin = 'bs';
            }
            
            auxMethods.push(m)     
        })

        //Si hay al menos un método de pago por tdd, se agrega el objeto tdd genérico para colocar en la lista inicial
        hasTdd && auxMethods.push({
            btn_img: "/images/mercantil_logo_2.svg",
            btn_alias: "TDD",
            btn_name: 'Tarjeta de débito',
            coin: "bs",
            btn_key: "debit_mock_key"
        })
        updateMethods(auxMethods)
    }

    /** 
     * Realiza la transacción del pago
     * @async
     * @function pay
     * @param {string} r el endpoint de pago
     * @param {Object} d data
     * @param {string} ak api key
     * @returns respuesta del servidor
     * 
    */
    const pay = async (r, d, ak) =>{

        const custom_field = {
            ...(info[0].custom_field && info[0].custom_field)
        }
        let is_cf_empty = true
        // eslint-disable-next-line no-unused-vars
        for (var x in custom_field) { is_cf_empty = false; break; }

        const data = {
            ...d,
            ...(info[0].client?.client_email && {c_email: info[0].client.client_email}),
            ...(!is_cf_empty && {custom_field: custom_field})
        };
        var ke= CryptoJS.enc.Utf8.parse(info[3].k)
        var iv =  CryptoJS.enc.Utf8.parse(info[3].i)
  
        var cipher = CryptoJS.AES.encrypt(JSON.stringify(data), ke, {
           iv: iv,
           mode: CryptoJS.mode.CBC,
        }).toString()

        const response = await clientAxios.post(`/${r}`, cipher, 
            {
                headers: {
                    Authorization: info[2].token,
                    'X-API-KEY': ak
                }
            }
        )
        return response
    }

    return(
        <clientContext.Provider
            value={{
                info:info,
                direction: direction,
                loading:state.loading,
                styleBrowserDefault:state.styleBrowserDefault,
                years:state.years,
                months:state.months,
                coins:state.coins,
                method:state.method,
                methods:state.methods,
                selectedMethods:state.selectedMethods,
                selectedCoin:state.selectedCoin,
                payment:state.payment,
                error_data:state.error_data,
                type_app: state.type_app,
                total: state.total,
                clientP2c: state.clientP2c,
                countries: state.countries,
                usMethods: state.usMethods,
                vesMethods: state.vesMethods,
                usCoinActive: state.usCoinActive,
                vesCoinActive: state.vesCoinActive,
                setLoading,
                changeMethod,
                changeMethodDebit,
                changeCoin,
                nextStep,
                handleError,
                pay,
                setTotal,
                setCountries,
                setDirection
            }}
        >
            {props.children}
        </clientContext.Provider>
    )

}

export default ClientState;



